<template>
  <div class="container">
    <div class="w-100">
      <section class="pt-5">
        <div class="text-centered w-100">
          <div>
            <img src="draft/mountsm.png" class="mount-icon" />
          </div>
          <div class="p-2">
            <span class="cool-button">OKOLICA</span>
          </div>
          <h1 class="mt-2">Poznaj naszą okolicę</h1>
        </div>
      </section>

      <section class="container pt-5 pb-5">
        <div class="container-item-6">
          <div class="info-image">
            <img src="draft/ocho1.png" class="w-100" />
          </div>
        </div>
        <div class="container-item-6">
          <div class="infopad">
            <h2>Odpoczynek w naturze</h2>
            <p>Okolica jest piękna, sporo szlaków turystycznych, w większości prowadzących przez Gorczański Park Narodowy, gdzie można zapomnieć o cywilizacji. Podczas długich spacerów po górach można naprawdę wypocząć psychicznie, wyłączyć na chwilę wszystkie zmartwienia i po prostu się zrelaksować. Dla lubiących szczyptę adrenaliny, możliwy przejażdżka offroad samochodem terenowym po górach.</p>
          </div>
        </div>
      </section>

      <section class="container pb-5">
        <div class="container-item-6">
          <div class="info-image">
            <img src="draft/ocho2.png" class="w-100" />
          </div>
        </div>
        <div class="container-item-6">
          <div class="infopad">
            <h2>Górskie atrakcje</h2>
            <p>Zimą bogaty wybór stoków narciarskich, a także spory wybór kąpielisk termalnych w niedalekich miejscowościach. Domek Znajduje się w górach Gorcach, ok 40 km od Zakopanego, w miejscowości Ochotnica Górna. W sąsiedztwie (15-20 km) znajduje się Szczawnica, Góry Pieniny, Spływ Dunajca, Niedzica, oraz wiele innych atrakcyjnych miejsc, które warto zobaczyć.</p>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AttractionsSection',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.infopad {
  padding: 20px 24px 24px 24px;
}

.infopad h2 {
  font-size: 32px;
  color: #2b965a;
}

.infopad p {
  font-size: 24px;
}

.info-image {
  padding: 16px 32px 0px 32px;
}

@media only screen and (min-width: 1280px) {
  .info-image img {
    width: calc(100% - 64px);
  }
}
@media only screen and (max-width: 1279px) {
  .info-image img {
    width: 100%;
  }
}
</style>
