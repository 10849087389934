<template>
  <div>
    <HeaderSection />
    <SliderSection />
    <OchotnicaSection />
    <InfoSection />
    <GallerySection />
    <AttractionsSection />
    <PerksSection />
    <FooterSection />
  </div>
</template>

<script>
import HeaderSection from './components/HeaderSection.vue'
import SliderSection from './components/SliderSection.vue'
import OchotnicaSection from './components/OchotnicaSection.vue'
import InfoSection from './components/InfoSection.vue'
import GallerySection from './components/GallerySection.vue'
import AttractionsSection from './components/AttractionsSection.vue'
import PerksSection from './components/PerksSection.vue'
import FooterSection from './components/FooterSection.vue'

export default {
  name: 'App',
  components: {
    HeaderSection,
    SliderSection,
    OchotnicaSection,
    InfoSection,
    GallerySection,
    AttractionsSection,
    PerksSection,
    FooterSection
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

body {
  font-size: 24px;
  margin: 0;
}

h1 {
  font-size: 48px;
  font-weight: bold;
  margin: 0;
}

@media only screen and (min-width: 1280px) {
  .container {
    width: 1280px;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
  }

  .container-half {
    width: 640px;
  }

  .container-fluid {
    display: flex;
    align-items: flex-start;
    height: 100%;
  }

  .container-item-6 {
    width: 50%;
  }

  .container-item-5 {
    width: 41.666%;
  }

  .container-item-4 {
    width: 33.2222%;
  }

  .container-item-3 {
    width: 25%;
  }

  .container-item-2 {
    width: 16.666%;
  }

  .container-item-1 {
    width: 8.333%;
  }
}

@media only screen and (max-width: 1279px) {
  .container {
    width: 96%;
    padding: 0 2%;
  }

  .container-half {
    width: 100%;
  }

  .container-fluid {
    height: 100%;
  }

  .container-item-6 {
    width: 100%;
  }

  .container-item-5 {
    width: 100%;
  }

  .container-item-4 {
    width: 100%;
  }

  .container-item-3 {
    width: 100%;
  }

  .container-item-2 {
    width: 100%;
  }

  .container-item-1 {
    width: 100%;
  }
}

.text-centered {
  text-align: center;
}

.cool-button {
  background-image: linear-gradient(200deg, #93de44, #2b965a);
  color: white;
  padding: 9px 19px;
  letter-spacing: 8px;
  font-size: 22px;
  border-radius: 16px;
}

.mount-icon {
  width: 180px;
  height: 70px;
}

.w-100 {
  width: 100%;
}

.pt-5 {
  padding-top: 50px;
}

.pb-5 {
  padding-bottom: 50px;
}

.pb-6 {
  padding-bottom: 64px;
}

.pt-3 {
  padding-top: 24px;
}

.p-1 {
  padding: 8px;
}

.p-2 {
  padding: 16px;
}

.p-3 {
  padding: 24px;
}

.p-4 {
  padding: 32px;
}

.p-5 {
  padding: 40px;
}

.pl-2 {
  padding-left: 16px;
}

.mt-2 {
  margin-top: 16px;
}

.mt-3 {
  margin-top: 24px;
}

.m-0 {
  margin: 0;
}

.media-img {
  width: 400px;
}
</style>
