<template>
  <div class="pt-5 pb-5">
    <section class="container pb-5">
      <h1 class="mt-2 w-100 text-centered">Galeria</h1>
    </section>
    <section class="container gallery">
      <div class="container-item-3">
        <img
          src="media/thumbs/domek-na-wynajem.jpg"
          @click="showImage('media/domek-na-wynajem.jpg')"
        />
      </div>
      <div class="container-item-3">
        <img src="media/thumbs/domek-podworko.jpg" @click="showImage('media/domek-podworko.jpg')" />
      </div>
      <div class="container-item-3">
        <img
          src="media/thumbs/hustawka-pod-drzewem.jpg"
          @click="showImage('media/hustawka-pod-drzewem.jpg')"
        />
      </div>
      <div class="container-item-3">
        <img
          src="media/thumbs/zjezdzalnia-scianka-wspinaczkowa.jpg"
          @click="showImage('media/zjezdzalnia-scianka-wspinaczkowa.jpg')"
        />
      </div>
    </section>
    <section class="container gallery">
      <div class="container-item-3">
        <img src="media/thumbs/drzwi-wejsciowe.jpg" @click="showImage('media/drzwi-wejsciowe.jpg')" />
      </div>
      <div class="container-item-3">
        <img
          src="media/thumbs/ozdoby-na-scianie.jpg"
          @click="showImage('media/ozdoby-na-scianie.jpg')"
        />
      </div>
      <div class="container-item-3">
        <img
          src="media/thumbs/wyposazenie-kominek.jpg"
          @click="showImage('media/wyposazenie-kominek.jpg')"
        />
      </div>
      <div class="container-item-3">
        <img
          src="media/thumbs/wyposazenie-kuchnia.jpg"
          @click="showImage('media/wyposazenie-kuchnia.jpg')"
        />
      </div>
    </section>
    <section class="container gallery">
      <div class="container-item-3">
        <img
          src="media/thumbs/wyposazenie-lazienka.jpg"
          @click="showImage('media/wyposazenie-lazienka.jpg')"
        />
      </div>
      <div class="container-item-3">
        <img
          src="media/thumbs/wyposazenie-materace.jpg"
          @click="showImage('media/wyposazenie-materace.jpg')"
        />
      </div>
      <div class="container-item-3">
        <img
          src="media/thumbs/wyposazenie-polka-wlaz.jpg"
          @click="showImage('media/wyposazenie-polka-wlaz.jpg')"
        />
      </div>
      <div class="container-item-3">
        <img
          src="media/thumbs/wyposazenie-ozdoby.jpg"
          @click="showImage('media/wyposazenie-ozdoby.jpg')"
        />
      </div>
    </section>
    <ZoomedImage :trigger="focusTrigger" :src="focusSrc"></ZoomedImage>
  </div>
</template>

<script>
import ZoomedImage from './ZoomedImage'

export default {
  name: 'GallerySection',
  components: { ZoomedImage },
  data: function () {
    return {
      focusSrc: '',
      focusTrigger: 0
    }
  },

  methods: {
    showImage(item) {
      this.focusSrc = item
      this.focusTrigger++
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.gallery img {
  width: calc(100% - 16px);
  margin: 4px 8px;
}
</style>
