<template>
  <div>
    <section class="container">
      <div class="infopad">
        <h2>Kontakt</h2>
        <p>
          Aby zarezerwować nasz domek w górach, prosimy o kontakt telefoniczny lub mailowy.
          Chętnie odpowiemy na wszystkie pytania oraz pomożemy w organizacji pobytu.
        </p>
      </div>
    </section>
  </div>
  <div class="green">
    <section class="container">
      <div class="container-item-6">
        <div class="infopad-b text-centered">
          <p>Postaw na swój wypoczynek!</p>
          <h2>Zarezerwuj domek</h2>
        </div>
      </div>
      <div class="container-item-1"></div>
      <div>
        <img src="draft/icophone.png" class="icophone" />
      </div>
      <div class="container-item-4">
        <div class="infopad-c">
          <h2>+48 789 321 805</h2>
          <h2>+48 511 064 452</h2>
        </div>
      </div>
    </section>
  </div>
  <div class="grey">
    <section class="container">
      <div class="container-item-4">
        <div class="address">
          <h1>DOMKI</h1>
          <h2>Ochotnica</h2>
        </div>
      </div>
      <div class="container-item-5">
        <div class="address">
          <p>
            <b>Domek na wynajem</b>
          </p>
          <p>Ochotnica Górna</p>
          <p>os. Kopcówka 301</p>
          <p>+48 789 321 805</p>
          <p>+48 511 064 452</p>
          <p>fhu.czajka81@o2.pl</p>
        </div>
      </div>
    </section>
  </div>
  <div class="dark">
    <section class="container">
      <p>
        <b>© Copyright 2023</b> OriCodes
      </p>
    </section>
  </div>
</template>

<script>
export default {
  name: 'FooterSection',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.infopad {
  padding: 20px 24px 24px 24px;
}

.infopad h2 {
  font-size: 32px;
  color: #2b965a;
}

.infopad p {
  font-size: 24px;
}

.infopad-b {
  padding: 32px 0px 32px 0px;
}

.infopad-b h2 {
  font-size: 32px;
  color: white;
  margin: 0;
}

.infopad-b p {
  font-size: 24px;
  color: white;
  margin: 0;
}

.infopad-c {
  padding: 26px 0px 0 32px;
}

.infopad-c h2 {
  font-size: 32px;
  color: white;
  margin: 0;
}

.green {
  background: #70c709;
}
.grey {
  background: #4a4c5b;
}
.dark {
  background: #2e2f38;
  color: white;
  padding: 16px;
}
.dark p {
  font-size: 20px;
  text-align: center;
  margin: 0;
  width: 100%;
}
@media only screen and (min-width: 1280px) {
  .icophone {
    margin-top: 16px;
    width: 96px;
  }
}
@media only screen and (max-width: 1279px) {
  .icophone {
    display: none;
  }
}

.address {
  padding: 32px 0 32px 16px;
}
.address p {
  color: white;
  margin: 0;
}
.address h1 {
  font-size: 64px;
  font-weight: bold;
  color: white;
  margin: 0;
}
.address h2 {
  font-size: 47px;
  font-weight: 100;
  color: white;
  margin: 0;
}
</style>
