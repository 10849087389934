<template>
  <section class="pt-5">
    <div class="text-centered w-100">
      <div class="p-2">
        <span class="cool-button">DLACZEGO MY?</span>
      </div>
      <h1 class="mt-2">Dlaczego warto nas odwiedzić</h1>
    </div>
  </section>
  <div class="box-base">
    <div class="box-back"></div>
    <section class="container pb-5 pt-5 text-centered">
      <div class="container-item-3">
        <div class="shadow-box">
          <div class="text-centered fix-height">
            <img src="draft/col1.png" />
          </div>
          <div class="perk-note">
            <h2>LOKALIZACJA</h2>
            <p>Nasz domek znajduje się w malowniczej okolicy w górach, co zapewnia ciszę i spokój. W okolicy znajdują się liczne szlaki turystyczne oraz atrakcje turystyczne, takie jak wyciągi narciarskie, parki linowe, baseny termalne itp.</p>
          </div>
        </div>
      </div>
      <div class="container-item-3">
        <div class="shadow-box">
          <div class="text-centered fix-height">
            <img src="draft/col2.png" />
          </div>
          <div class="perk-note">
            <h2>WYPOSAŻENIE</h2>
            <p>Nasz domek oferuje komfortowe warunki pobytu. Posiada w pełni wyposażoną kuchnię, łazienkę z prysznicem, salon z kominkiem oraz sypialnie. Dostępne jest również miejsce na ognisko i grilla.</p>
          </div>
        </div>
      </div>
      <div class="container-item-3">
        <div class="shadow-box">
          <div class="text-centered fix-height">
            <img src="draft/col3.png" class="mt-2" />
          </div>
          <div class="perk-note">
            <h2>POŁOŻENIE</h2>
            <p>Nasz domek jest usytuowany w cichej i spokojnej okolicy, z dala od zgiełku miasta. W pobliżu znajduje się sklep spożywczy oraz restauracje.</p>
          </div>
        </div>
      </div>
      <div class="container-item-3">
        <div class="shadow-box">
          <div class="text-centered fix-height">
            <img src="draft/col4.png" />
          </div>
          <div class="perk-note">
            <h2>CENA</h2>
            <p>Cena wynajmu naszego domku w górach jest uzależniona od sezonu oraz ilości osób. Zapewniamy konkurencyjne ceny oraz elastyczność w negocjacjach.</p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'PerksSection',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.fix-height {
  height: 128px;
}

.perk-note {
  padding: 16px;
}

.perk-note h2 {
  font-size: 24px;
}

.perk-note p {
  font-size: 18px;
}

.shadow-box {
  width: calc(100% - 64px);
  margin: 32px;
  height: 440px;
  border-radius: 16px;
  background: white;
  -webkit-box-shadow: 0px 8px 18px 0px rgba(127, 158, 141, 0.8);
  -moz-box-shadow: 0px 8px 18px 0px rgba(127, 158, 141, 0.8);
  box-shadow: 0px 8px 18px 0px rgba(127, 158, 141, 0.8);
}

.shadow-box img {
  width: 84px;
  padding-top: 48px;
}

.box-base {
  position: relative;
}

.box-back {
  background: #f3faeb;
  position: absolute;
  bottom: 0;
  height: 300px;
  z-index: -1;
  width: 100%;
}
</style>
