<template>
  <section class="pt-5">
    <div class="text-centered w-100">
      <div>
        <img src="draft/mountsm.png" class="mount-icon" />
      </div>
      <div class="p-2">
        <span class="cool-button">WYPOCZYNEK</span>
      </div>
      <h1 class="mt-2">Ochotnica Górna, kraina wypoczynku</h1>
    </div>
  </section>

  <section class="container pt-5 pb-5">
    <div class="container-item-6">
      <img src="draft/ocho.png" class="w-100 rounded-image" />
    </div>
    <div class="container-item-6">
      <div class="infopad">
        <h2>Poznaj Ochotnicę Górną...</h2>
        <p>...urokoliwą wieś położoną w powiecie nowtarskim. Miejscowość nie tylko ma wiele do zaoferowania, ale stanowi też świetną bazę wypadową do dalszych górskich wędrówek.</p>
        <img src="draft/arrow.png" class="mount-icon" />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'OchotnicaSection',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media only screen and (min-width: 1280px) {
  .infopad img {
    width: 400px;
    height: 140px;
    transform: rotate(-20deg);
  }
}
@media only screen and (max-width: 1279px) {
  .infopad img {
    display: none;
  }
}

.rounded-image {
  border-radius: 28px;
}

.infopad {
  padding: 8px 0 8px 32px;
}

.infopad h2 {
  font-size: 32px;
  color: #2b965a;
  padding-left: 16px;
}

.infopad p {
  font-size: 24px;
  padding-left: 16px;
}
</style>
