<template>
  <section class="container">
    <div class="container-item-6 logo-container">
      <img src="draft/logo.png" alt="Logo" />
    </div>
    <div class="container-item-3 call-reserve">Zadzwoń i zarezerwuj!</div>
    <div class="container-item-3 top-pad">
      <div class="phone-wrap">
        <div class="contact-box">
          <img src="draft/phone.png" alt="Ikona telefonu" />
          <div>
            <p>789 321 805</p>
            <p>511 064 452</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'HeaderSection',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media only screen and (min-width: 1280px) {
  .logo-container {
    margin-top: 8px;
    margin-bottom: 12px;
  }

  .logo-container img {
    height: 132px;
  }

  .contact-box {
    display: flex;
    width: 16rem;
    background: #2b965a;
    border-radius: 24px;
  }

  .contact-box img {
    width: 48px;
    height: 48px;
    margin: 8px 16px;
  }

  .contact-box p {
    font-size: 24px;
    font-weight: bold;
    color: white;
    margin: 3px 0 0 8px;
  }

  .phone-wrap {
    padding-top: 42px;
  }

  .call-reserve {
    font-size: 30px;
    margin-top: 57px;
  }
}
@media only screen and (max-width: 1279px) {
  .logo-container {
    margin-top: 8px;
    margin-bottom: 8px;
    text-align: center;
  }

  .logo-container img {
    height: 100px;
  }

  .contact-box {
    margin: 8px auto 16px auto;
    display: flex;
    width: 16rem;
    background: #2b965a;
    border-radius: 24px;
  }

  .contact-box img {
    width: 48px;
    height: 48px;
    margin: 8px 16px;
  }

  .contact-box p {
    font-size: 24px;
    font-weight: bold;
    color: white;
    margin: 3px 0 0 8px;
  }

  .phone-wrap {
    padding-top: 8px;
  }

  .call-reserve {
    font-size: 24px;
    text-align: center;
    margin-top: 4px;
  }
}
</style>
