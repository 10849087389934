<template>
  <div class="container-fluid">
    <div class="half-size half-size1 slider-bg"></div>
    <div class="half-size half-size2 greenish">
      <div class="mountains">
        <img src="draft/mountains.png" alt="Góry" />
        <div class="mountains-note">
          <p>OCHOTNICA GÓRNA</p>
          <h3>Domek na wynajem</h3>
        </div>
      </div>
      <div class="info-note">
        <div class="container-half">
          <div class="info-note-item">
            <p>Zapraszamy do wynajęcia naszego uroczego domku w górach, który oferuje wspaniałe widoki na piękne krajobrazy i pozwala na wspaniały wypoczynek wśród natury.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SliderSection',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media only screen and (min-width: 1280px) {
  .slider-bg {
    flex: 1 1 auto;
    display: flex;
    background: url("../assets/slider.png");
    background-size: cover;
    background-position-y: center;
    background-position-x: 100%;
  }

  .half-size {
    width: 50%;
    height: 700px;
  }

  .greenish {
    background: #70c709;
  }

  .mountains {
    position: relative;
    padding: 50px 0 10px 40px;
  }

  .mountains img {
    opacity: 0.2;
    width: 700px;
    height: 200px;
  }

  .mountains-note {
    position: absolute;
    top: 146px;
    z-index: 2;
    color: white;
    padding-left: 10px;
  }

  .mountains-note p {
    font-size: 22px;
    letter-spacing: 8px;
    margin: 0;
  }

  .mountains-note h3 {
    font-size: 60px;
    font-weight: bold;
    margin: 0;
  }

  .info-note {
    background: rgba(0, 0, 0, 0.098);
    font-size: 32px;
    margin-top: 42px;
  }

  .info-note-item {
    padding: 50px 0px 50px 54px;
    color: white;
    font-size: 32px;
  }

  .info-note-item p {
    margin: 0;
  }
}
@media only screen and (max-width: 1279px) {
  .slider-bg {
    background: url("../assets/slider.png");
    background-size: cover;
    background-position-y: center;
    background-position-x: 100%;
  }

  .half-size1 {
    width: 100%;
    height: 480px;
  }

  .half-size2 {
    width: 100%;
  }

  .greenish {
    background: #70c709;
  }

  .mountains {
    position: relative;
    padding: 50px 20px 10px 20px;
  }

  .mountains img {
    opacity: 0.2;
    height: 124px;
  }

  .mountains-note {
    position: absolute;
    top: 26px;
    z-index: 2;
    color: white;
    padding-left: 10px;
  }

  .mountains-note p {
    font-size: 22px;
    letter-spacing: 8px;
    margin: 0;
  }

  .mountains-note h3 {
    font-size: 52px;
    font-weight: bold;
    margin: 0;
    margin-top: 1rem;
    line-height: 3.4rem;
  }

  .info-note {
    background: rgba(0, 0, 0, 0.098);
    font-size: 32px;
  }

  .info-note-item {
    padding: 24px 16px 24px 16px;
    color: white;
    font-size: 32px;
  }

  .info-note-item p {
    margin: 0;
  }
}
</style>
