<template>
  <Transition>
    <div v-if="visible" class="zoomer-background" @click="visible = false">
      <img :src="src" />
    </div>
  </Transition>
</template>

<script>
export default {
  name: 'ZoomedImage',

  data: function () {
    return {
      visible: false
    }
  },

  props: {
    trigger: { type: Number, required: false },
    src: { type: Boolean, required: false }
  },

  watch: {
    trigger() {
      this.visible = true
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.v-enter-from {
  opacity: 0;
}
.v-enter-to {
  opacity: 1;
}
.v-enter-active {
  transition: opacity 0.35s ease;
}

.v-leave-from {
  opacity: 1;
}
.v-leave-to {
  opacity: 0;
}
.v-leave-active {
  transition: opacity 0.35s ease;
}

.zoomer-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.341);
  z-index: 5;
  text-align: center;
}

.zoomer-background img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 96vw;
  max-height: 96vh;
}
</style>
