<template>
  <div class="light-green pt-5 pb-5">
    <section class="pt-3 pb-3">
      <div class="text-centered w-100">
        <div class="p-2">
          <span class="cool-button">DOMEK</span>
        </div>
        <h1 class="mt-2">Nasz domek na wynajem</h1>
        <p>Altana wielosezonowa, do wynajęcia każdą porą roku. Jest to niewielki domek mieszczący maksymalnie 4 osoby.</p>
      </div>
    </section>
    <section class="container">
      <h3 class>Wyposażenie i udogodnienia</h3>
    </section>
    <section class="container">
      <div class="container-item-1">
        <div class="icon-box">
          <img src="draft/icon-home.png" />
        </div>
      </div>
      <div
        class="container-item-5 note-pad"
      >Mały aneks kuchenny, łazienka, mała sypialnia na piętrze.</div>
      <div class="container-item-1">
        <div class="icon-box">
          <img src="draft/icon-ppl.png" />
        </div>
      </div>
      <div
        class="container-item-5 note-pad"
      >W ofercie także możliwość wynajmu pokoi dla większych grup.</div>
    </section>
    <section class="container mt-3">
      <div class="container-item-1">
        <div class="icon-box">
          <img src="draft/icon-fire.png" />
        </div>
      </div>
      <div class="container-item-5 note-pad">Kominek z możliwością grillowania.</div>
      <div class="container-item-1">
        <div class="icon-box">
          <img src="draft/icon-soup.png" />
        </div>
      </div>
      <div class="container-item-5 note-pad">Możliwość spróbowania regionalnej kuchni.</div>
    </section>
    <section class="container mt-3">
      <div class="container-item-1">
        <div class="icon-box icon-box-mod">
          <img src="draft/icon-water.png" />
        </div>
      </div>
      <div
        class="container-item-5 note-pad"
      >Obok chatki jest oczko z lodowatą górską wodą oraz rzeka, na której co roku, latem, budowana jest tama (kąpielisko), gdzie można świetnie popływać w orzeźwiającej wodzie.</div>
      <div class="container-item-1">
        <div class="icon-box">
          <img src="draft/icon-run.png" />
        </div>
      </div>
      <div
        class="container-item-5 note-pad"
      >Huśtawki, miejsce do gry w piłkę, lub też do rozbicia namiotu.</div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'InfoSection',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.light-green {
  background: #f3faeb;
}

.icon-box {
  width: 80px;
  height: 64px;
  background-color: white;
  border-radius: 16px;
  text-align: center;
  padding-top: 16px;
  margin: 0 auto;

  -webkit-box-shadow: 0px 8px 18px 0px rgba(127, 158, 141, 0.8);
  -moz-box-shadow: 0px 8px 18px 0px rgba(127, 158, 141, 0.8);
  box-shadow: 0px 8px 18px 0px rgba(127, 158, 141, 0.8);
}


@media only screen and (min-width: 1280px) {
  .icophone {
    margin-top: 16px;
    width: 96px;
  }
}
@media only screen and (max-width: 1279px) {
  .icophone {
    display: none;
  }
}

.icon-box img {
  height: 48px;
}

.icon-box-mod img {
  width: 48px;
}

.note-pad {
  padding: 16px 32px 16px 16px;
}
</style>
